app-partner-edit {
	p-avatar > div.p-avatar-circle.p-avatar-xl {
		width: 90px;
		height: 90px;

		.p-avatar-text {
			font-size: 26px;
		}
	}

	.partner-id {
		border: 1px solid $warning;
		border-radius: 8px;
		background-color: white;
	}

	label {
		font-weight: 600;
		font-size: $font-size-base * 0.8125;
		color: $sideBarText;
	}

	.no-dialog-header .p-dialog-content {
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
	}

	.partner-avatar {
		color: #faae44;
		.p-avatar-circle {
			background-color: #fae8ce;
		}
	}

	.dropdown-toggle:hover {
		cursor: pointer;
	}

	.dropdown-toggle::after {
		display: none;
	}

	.p-tabview-title {
		font-size: $font-size-xs;
	}

	.p-tabview .p-tabview-nav {
		background: transparent;
		font-size: 1rem;
		flex-wrap: wrap;
		border: solid #dee2e6;
		border-width: 0 0 2px 0;
	}

	.p-tabview .p-tabview-nav li .p-tabview-nav-link {
		background: transparent;
		margin-right: 0;
		justify-content: center;
	}

	.p-tabview .p-tabview-nav li .p-tabview-nav-link {
		background: transparent;
	}

	.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
		background: transparent;
		color: $primary;
		border-color: $primary;
	}

	.p-tabview .p-tabview-nav .p-tabview-ink-bar {
		background: none;
	}

	.p-tabview .p-tabview-panels {
		background: transparent;
		padding-left: 0;
		padding-right: 0;
	}

	.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
		background-color: transparent;
	}

	.p-tabview .p-tabview-nav {
		li.p-highlight .p-tabview-nav-link .p-tabview-title,
		li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link .p-tabview-title {
			font-weight: 700;
		}

		.p-tabview-title {
			font-size: 15px;
			font-weight: 400;
		}
	}

	@media screen and (max-width: 1199px) {
		.p-tabview .p-tabview-nav {
			justify-content: space-between;
			margin: 0;
		}

		.p-tabview .p-tabview-nav li {
			flex-grow: 1;
		}

		.p-tabview .p-tabview-nav li .p-tabview-nav-link {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
	}

	@media screen and (max-width: 556px) {
		.login-as-partner-btn {
			width: 100%;
		}
	}
}
