app-ce-course-video {
	height: 100%;
	display: flex;
	flex-direction: column;

	.plyr-embedded {
		position: relative;
		// margin: 0 auto 40px;
		padding-bottom: 56.25%;
		margin-bottom: 25px;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.p-editor-toolbar {
		display: none;
	}

	.ql-toolbar.ql-snow + .ql-container.ql-snow {
		border: none;
	}
	.plyr--full-ui.plyr--video .plyr__control--bigvr {
		background-image: url(../../assets/images/play.png);
		width: 70px;
		height: 70px;
	}

	.video-data {
		.ql-align-right {
			text-align: end;
		}

		.ql-align-center {
			text-align: center;
		}

		.ql-align-left {
			text-align: start;
		}

		p {
			margin-bottom: 0;

			img {
				max-width: 100%;
				max-height: 100%;
				height: auto !important;
				width: auto !important;
			}
		}
	}
}
