app-progress {
	p-avatar > div.p-avatar-circle.p-avatar-xl {
		width: 90px;
		height: 90px;

		.p-avatar-text {
			font-size: 26px;
		}
	}

	.student-avatar {
		color: #faae44;
		.p-avatar-circle {
			background-color: #fae8ce;
		}
	}

	.view-program-main {
		padding: 0 8rem;
	}

	.p-progressbar {
		height: 0.4rem;

		.p-progressbar-label {
			display: none !important;
		}
	}

	.p-progressbar-determinate .p-progressbar-value {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
		background: $primary;
	}

	.completed {
		i,
		div {
			color: #47b99e !important;
		}
	}

	.course-selected {
		display: none;
	}

	.select-course {
		display: block;
	}

	.p-checkbox-label {
		color: $primary;
	}

	.p-checkbox .p-checkbox-box.p-highlight {
		color: $secondary;
		background-color: $secondary;
	}

	.p-datatable .p-datatable-tbody > tr:has(.p-checkbox .p-checkbox-box.p-highlight) > td {
		.text-data,
		label {
			color: $secondary;
		}

		img {
			filter: invert(24%) sepia(98%) saturate(1296%) hue-rotate(177deg) brightness(70%)
				contrast(90%);
		}

		.course-selected {
			display: block;
		}

		.select-course {
			display: none;
		}
	}

	.progress-main {
		padding: 1rem 8rem;
	}

	@include media-breakpoint-down(lg) {
		.view-program-main {
			padding: 0 1rem;
		}
	}
}
