app-ce-course-exam-pre-test {
	height: 100%;
	display: flex;
	flex-direction: column;

	.pretest-container {
		margin-top: 2rem;

		@include media-breakpoint-up(xl) { 
			margin-top: 10rem;
		}
	}

	.begin-test-button {
		width: 100%;

		@include media-breakpoint-up(xl) { 
			width: auto;
		}
	}

	.p-tabview-title {
		font-size: $font-size-xs;
	}

	.p-tabview .p-tabview-nav {
		background: transparent;
		font-size: 1rem;
		flex-wrap: wrap;
		border: solid #dee2e6;
		border-width: 0 0 2px 0;
	}

	.p-tabview .p-tabview-nav li .p-tabview-nav-link {
		background: transparent;
		margin-right: 0;
		justify-content: center;
	}

	.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
		background-color: transparent;
	}

	.p-tabview .p-tabview-nav {
		li.p-highlight .p-tabview-nav-link .p-tabview-title,
		li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link .p-tabview-title {
			font-weight: 700;
		}

		.p-tabview-title {
			font-size: 15px;
			font-weight: 400;
		}
	}

	.p-tabview .p-tabview-nav li .p-tabview-nav-link {
		background: transparent;
	}

	.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
		background: transparent;
		color: $primary;
		border-color: $primary;
	}

	.p-tabview .p-tabview-nav .p-tabview-ink-bar {
		background: none;
	}

	.p-tabview .p-tabview-panels {
		background: transparent;
		padding-left: 0;
		padding-right: 0;
	}

	.exam-test-details-title {
		text-align: center;
		color: $primary;
		background: #fafbfc;
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
	}

	.exam-test-info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 1px solid rgb(0, 0, 0, 0.25);
	}

	.quiz-results-title {
		text-align: center;
		color: $secondary;
		background: #eaf7ff;
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
	}

	.quiz-results-info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 1px solid rgb(0, 0, 0, 0.25);
	}
}
