#dashboard {
	min-width: 240px;
	overflow-y: hidden;
}

#main-page {
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}

#page-content {
	height: 100%;
	min-height: 0px;
}

.page-content-margins {
	margin: 2rem 3rem;
}

.session-block-card-header {
	font-size: 22px;
	color: $primary;
}

.session-block-name-input {
	color: $primary;
}

.card {
	border-radius: 1rem;
}

ngx-datatable.ngx-datatable.material {
	background-color: transparent;
	.datatable-header {
		.datatable-header-cell {
			background-color: transparent;
		}
	}
}

#abp-http-error-container {
	display: none;
}

.p-component {
	font-family: 'SofiaPro';
	font-size: $font-size-base;

	&.p-tabmenu {
		margin-bottom: 1rem;

		.p-tabmenu-nav {
			background-color: transparent;
			.p-tabmenuitem .p-menuitem-link {
				color: tint-color($sideBarText, 40%);
			}
			.p-tabmenuitem.p-highlight .p-menuitem-link.p-menuitem-link-active {
				color: $primary;
				border-color: $primary;
				font-weight: 700;
			}
			.p-tabmenuitem:hover,
			.p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
				background-color: transparent;
			}
			.p-tabmenuitem .p-menuitem-link,
			.p-tabmenuitem.p-highlight .p-menuitem-link {
				background-color: transparent;
				font-weight: 400;
			}
		}
	}
}

.p-button:enabled:hover {
	background-color: var(--#{$prefix}btn-hover-bg);
	border-color: var(--#{$prefix}btn-hover-border-color);
}

.p-button.p-button-text {
	color: $secondary;
}

.p-inputtext {
	font-family: 'SofiaPro';
}

.gray-text {
	color: $sideBarText;
}

.primary-text {
	color: $primary;
}

.secondary-text {
	color: $secondary;
}

.fs-xs {
	font-size: $font-size-xs;
}

.fs-sm {
	font-size: $font-size-sm;
}

.fs-m {
	// 18 px
	font-size: $font-size-m;
}

.fs-7 {
	// 15px
	font-size: $font-size-base * 0.9375;
}

.fs-8 {
	// 14px
	font-size: $font-size-base * 0.875;
}

.fs-9 {
	// 13px
	font-size: $font-size-base * 0.8125;
}

.fw-medium {
	font-weight: 500;
}

button.btn {
	font-size: $font-size-base * 0.9375;
}

.btn-save {
	padding-top: 0.6rem;
	padding-bottom: 0.6rem;
	margin-bottom: 1rem;
	width: 100%;

	@include media-breakpoint-up(xl) {
		width: 10rem;
		margin-bottom: 0px;
	}
}

.btn-cancel {
	padding-top: 0.6rem;
	padding-bottom: 0.6rem;
	margin-right: 0;
	width: 100%;

	@include media-breakpoint-up(xl) {
		width: 10rem;
		margin-right: 1.5rem;
	}
}

a.btn-back {
	color: $sideBarText;
	background-color: transparent;
	border-width: 0;
}
a.btn-back:hover,
a.btn-back:first-child:active {
	color: tint-color($sideBarText, 40%);
}

a.dropdown-item {
	color: $sideBarText;
}
a.dropdown-item:active,
a.dropdown-item:hover {
	color: $secondary;
	background-color: transparent;
}

.base-tab-panel.nav-tabs {
	.nav-item {
		padding-left: 1rem;
		padding-right: 1rem;
		.nav-link {
			position: relative;
			padding-left: 0;
			padding-right: 0;
			border-width: 0px;
			color: $sideBarText;
			font-size: $font-size-sm;
		}
		.nav-link.active,
		.nav-link:hover {
			background-color: transparent;
			color: $primary;
			font-weight: 600;
		}
	}

	.nav-link::after {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0.25rem;
		content: '';
		background-color: $primary;
		border-radius: 4px 4px 0px 0px;
		opacity: 0;
		visibility: hidden;
	}

	.nav-link.active::after,
	.nav-link:hover::after {
		opacity: 1;
		visibility: visible;
	}
}

textarea.form-control::placeholder,
input[type='text'].form-control::placeholder {
	color: tint-color($sideBarText, 40%);
}
textarea.form-control::-moz-placeholder,
input[type='text'].form-control::-moz-placeholder {
	opacity: 1;
	color: tint-color($sideBarText, 40%);
}
textarea.form-control::-webkit-input-placeholder,
input[type='text'].form-control::-webkit-input-placeholder {
	color: tint-color($sideBarText, 40%);
}

ngb-modal-window > .modal-dialog > .modal-content {
	border-radius: 1rem;
	border-color: transparent;

	.modal-header {
		font-weight: 400;
		color: $primary;
		border-bottom: 0px;
	}

	.modal-body {
		label {
			font-size: $font-size-base * 0.8125;
			font-weight: 600;
			color: $sideBarText;
		}
		color: $sideBarText;
	}

	.modal-footer {
		border-top: 0px;
		.btn {
			font-size: $font-size-base * 0.9375;
		}
	}
}

.p-dialog {
	box-shadow: none;
	width: 498px;
	.p-dialog-header {
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
		.p-dialog-title {
			font-size: 24px;
			font-weight: 500;
			color: $primary;
		}
	}

	.p-dialog-content {
		color: $sideBarText;

		form {
			label {
				font-weight: 600;
				font-size: $font-size-base * 0.8125;
				color: $sideBarText;
			}

			input {
				width: 100%;
				border-radius: 3px;
				border-width: 1px;
				border-style: solid;
				border-color: tint-color($sideBarText, 50%);
			}

			input:focus-visible {
				outline-color: tint-color($sideBarText, 50%);
			}

			::placeholder,
			::-webkit-input-placeholder {
				color: tint-color($sideBarText, 50%);
			}
		}
	}

	.p-dialog-footer {
		border-bottom-left-radius: 1rem;
		border-bottom-right-radius: 1rem;

		.p-icon-wrapper {
			display: none;
		}
	}
}

.base-table .p-datatable {
	th:first-child {
		border-top-left-radius: 1rem;
		border-bottom-left-radius: 1rem;
	}

	th:last-child {
		border-top-right-radius: 1rem;
		border-bottom-right-radius: 1rem;
	}
	.p-datatable-tbody > tr > td {
		color: $sideBarText;
		a {
			color: $secondary;
		}

		.trash-icon[role='button'] {
			color: $sideBarText;
		}

		.trash-icon[role='button']:hover {
			color: $primary;
		}
	}

	.p-datatable-thead > tr {
		background-color: $light;
		> th {
			color: $sideBarText;
			background-color: transparent;
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
			font-weight: 400;
		}
	}
}

.btn-save {
	padding-top: 0.6rem;
	padding-bottom: 0.6rem;
	width: 10rem;
}

.btn-cancel {
	padding-top: 0.6rem;
	padding-bottom: 0.6rem;
	margin-right: 1.5rem;
	width: 10rem;
}

.btn:disabled {
	background: #e9ecef;
	color: #bbbbbb;
	border-color: #bbbbbb;
}

@media screen and (max-width: 1199px) {
	.btn-save {
		width: 100%;
		margin-bottom: 1rem;
	}

	.btn-cancel {
		margin-right: 0;
		width: 100%;
	}

	#dashboard {
		display: none !important;
	}

	.page-content-margins {
		margin: 1rem 1rem;
	}
}

@media screen and (max-width: 960px) {
	.trash-icon[role='button'] {
		font-size: 2rem !important;
	}
}

.base-table .p-datatable {
	.p-datatable-thead > tr > th,
	.p-datatable-tbody > tr:last-child > td,
	.p-paginator-bottom {
		border-width: 0;
	}
}

.disable-scroll .ui-dialog-content {
	overflow: hidden !important;
}

.bg-main {
	background: #fafbfc;
}

.p-fileupload {
	.p-button {
		background: $secondary;
		border-color: $secondary;
	}

	.p-button:enabled:hover {
		background: $secondary;
		border-color: $secondary;
	}
}
