app-course-sessions {
  .w-6-5 {
    width: 6.5rem;
  }

  .fit-content-xxs {
    @media (max-width: 418px) {
      width: fit-content;
    }
  }

  .session-block {
    margin-bottom: 3rem;
  }

  .content-block-modal .p-dialog {
    width: 550px;

    .p-dialog-content {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
  }

  .card-body-content {
    padding: 1rem 2.5rem !important;
    padding-top: 2rem !important;
  }

  .color-primary {
    color: $primary;
  }

  .p-datatable-reorderablerow-handle,
  [pReorderableColumn] {
    width: 6px;
  }

  .p-button.p-button-text {
    color: $secondary;
  }

  .btn-back {
    color: $sideBarText;
    background-color: transparent;
    border-width: 0;
  }

  .btn-back:hover,
  .btn-back:first-child:active {
    color: tint-color($sideBarText, 40%);
  }

  .p-togglebutton.p-button {
    width: 8rem;
    height: 8rem;
    padding: 5px;
  }

  .p-togglebutton.p-button.p-highlight {
    background: #F3F8FB;
    border-color: rgba(13, 114, 170, 0.25);
    color: $secondary;

    .p-button-icon-left {
      color: $secondary;
    }
  }

  .p-togglebutton.p-button.p-highlight:hover {
    background: $secondary;
    background-color: $secondary;
  }

  .p-button-icon-left {
    align-self: start;
    font-weight: bold;
  }

  .p-button-label {
    text-align: left;
    margin-left: 1rem;
  }

  .flashcards-toggle .p-button-label {
    margin-left: 0;
  }

  .text-align-center {
    text-align: center;
  }

  @media screen and (max-width: 1199px) {
    .card-body-content {
      padding: 0.5rem !important;
    }
  }
}
