app-view-course {
    .view-course-main {
        padding: 0 8rem;
    }
    
    .p-button > span.p-button-label {
        font-weight: normal;
    }
    
    .custom-marker {
        display: flex;
        width: 2rem;
        height: 2rem;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border-radius: 50%;
        z-index: 1;
    }
    
    .p-timeline .p-timeline-event-connector {
        background-color: #673AB7;
    }
    
    .p-timeline-left .p-timeline-event-opposite {
        display: none;
    }
    
    .background-image {
        position: relative;
    }
    
    .course-icon {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-image: url(../../assets/images/courses/labor-bg.png);
        background-position: bottom left;
        background-size: 230px auto;
        background-repeat: no-repeat;
        opacity: 0.5;
        z-index: 1;
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    .title-row {
        padding-left: 8rem;
        padding-bottom: 8rem;
        padding-top: 8rem;
        padding-right: 2rem;
        z-index: 2;
    }

    .course-title {
        font-size: 45px;
    }

    .btn-register {
        padding: 0.5rem 4rem
    }

    .btn-preview {
        padding: 0.5rem 2.8rem
    }

    .btn-continue {
        padding: 0.4rem 4rem;
        background-color: #fafbfc;
    }

    .btn-view-certificate {
        padding: 0.4rem 4rem;
        background-color: #fafbfc;
    }

    .btn-start {
        padding: 0.4rem 4rem;
    }
    
    .img-course {
        padding-right: 8rem;

        img.course-image {
            width: 350px;
            height: 350px;
            object-fit: cover;
            object-position: center;
            margin-bottom: -32rem;
            border-radius: 2rem;
        }

        .placeholder-image {
            width: 350px; 
            height: 350px;
            padding-top: 150px; 
            padding-bottom: 150px;
            margin-bottom: -32rem;
            border-radius: 2rem;
        }
    }

    .session-block {
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
    }

    .btn-back-to-courses {
        padding-left: 8rem;

        button {
            padding: 0.5rem 1rem
        }
    }

    .course-info-row {
        padding-left: 8rem;
        padding-right: 8rem;
    }

    .session-description {
        white-space: pre-line;
    }

    .img-certificate {
        background-color: #fafbfc;
        border-radius: 2rem;
    }

    .p-progressbar {
        height: 0.4rem;

        .p-progressbar-label {
            display: none !important;
        }

        .p-progressbar-value {
            background: $primary;
        }
    }

    .p-progressbar-determinate .p-progressbar-value {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .p-steps .p-steps-item .p-menuitem-link {
        background: #fafbfc;
    }
    .p-steps .p-steps-item.p-highlight .p-steps-number {
        background: $primary;
        color: white !important;
        min-width: 2.5rem !important;
        height: 2.5rem !important;
        box-shadow: 0 0 0 0.5rem rgb(0 36 51 / 10%);
        padding: 0.5rem 0;
        display: flex;
    }

    .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
        color: $primary;
        background: $primary;
        min-width: 1.2rem;
        height: 1.2rem;
    }

    .p-steps-title {
        display: none;
    }

    .p-steps ul {
        padding: 0 2rem;
    }

    .p-steps ul > li:first-child {
        justify-content: left;
        
        a {
            margin-left: -2rem;
        }
    }

    .p-steps ul > li:last-child {
        justify-content: right;

        a {
            margin-right: -2rem;
        }
    }

    .p-steps .p-steps-item .p-menuitem-link {
        min-width: 4rem;
        height: 4rem;
        justify-content: center;
        margin-top: -2rem;
    }

    .p-steps .p-disabled {
        opacity: 1;
    }

    @include media-breakpoint-down(xl) {
        .btn-register {
            width: 100%;
        }
    
        .btn-preview {
            margin-top: 1rem;
            width: 100%;
        }

        .btn-continue {
            width: 100%;
        }

        .btn-view-certificate {
            width: 100%;
        }
    }

    @include media-breakpoint-down(lg) {
        .view-course-main {
            padding: 0 1rem;
        }

        .background-image::before {
            background-image: none;
        }

        .background-image {
            background-color: transparent;
        }

        .title-row {
            padding: 2rem 2rem 2rem 2rem;
        }

        .course-title {
            font-size: 30px;
            margin-top: 0;
        }

        .img-course {
            padding-left: 2rem;
            padding-right: 2rem;

            img.course-image {
                margin-bottom: 0;
                width: 100%;
                max-width: 350px;
            }

            .placeholder-image {
                display: none;
            }
        }

        .btn-back-to-courses {
            padding-left: 2rem;
            padding-right: 2rem;
    
            button {
                width: 100%;
            }
        }

        .course-info-row {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

}