app-courses-in-progress {
	.overall-progress {
		label {
			color: $secondary;
		}

		.p-progressbar .p-progressbar-value {
			background: $secondary;
		}
	}

	@media screen and (max-width: 730px) {
		.in-progress-card {
			min-width: 100%;
		}
	}

	@include media-breakpoint-down(md) {
		.btn-secondary {
			width: 100%;
			margin-top: 1rem !important;
			margin-right: 0 !important;
		}
	}

	@media screen and (max-width: 700px) {
		.courses-cards {
			flex: 0 0 auto;
			width: 100%;
		}
	}

	@media (min-width: 701px) {
		.courses-cards {
			flex: 0 0 auto;
			width: 50%;
		}
	}

	@media (min-width: 1100px) {
		.courses-cards {
			flex: 0 0 auto;
			width: 33.33333333%;
		}
	}

	@media (min-width: 1200px) {
		.courses-cards {
			flex: 0 0 auto;
			width: 50%;
		}
	}

	@media (min-width: 1400px) {
		.courses-cards {
			flex: 0 0 auto;
			width: 33.33333333%;
		}
	}

	@media (min-width: 1700px) {
		.courses-cards {
			flex: 0 0 auto;
			width: 25%;
		}
	}

	@media (min-width: 2000px) {
		.courses-cards {
			flex: 0 0 auto;
			width: 20%;
		}
	}

	@media (min-width: 2300px) {
		.courses-cards {
			flex: 0 0 auto;
			width: 16.6666666667%;
		}
	}
}
