app-register-code {
    .p-input-icon-right>i {
        margin-top: -0.7rem;
    }

    .p-inputtext.ng-dirty.ng-invalid {
        border-color: tint(#C52024, 85%);
        background-color: tint(#C52024, 96%);
        color: tint(#C52024, 40%);
    }

    .p-input-icon-right .isax-close-circle {
        color: tint(#C52024, 40%);
    }

    .invalid-code {
        color: tint(#C52024, 40%);
    }

    .p-inputtext.ng-valid {
        border-color: tint(#47B99E, 60%);
        background-color: tint(#47B99E, 95%);
        color: #47B99E;
    }

    .p-input-icon-right .isax-tick-circle {
        color: #47B99E;
    }
}