app-ce-course-quiz {
	height: 100%;
	display: flex;
	flex-direction: column;

	.p-progressbar {
		height: 0.6rem;
		background: tint(#0d72aa, 90%);
	}

	.p-checkbox .p-checkbox-box .p-checkbox-icon {
		color: $secondary;
		font-weight: bold;
	}

	.p-checkbox .p-checkbox-box.p-highlight {
		border-color: $secondary;
		background: #eaf7ff;
		border: 1px solid $secondary;
	}

	.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
		border-color: $secondary;
		background: tint($secondary, 60%);
	}

	.field-checkbox:has(.p-checkbox-box.p-highlight) label {
		color: $secondary !important;
	}

	.quiz-results-title {
		text-align: center;
		color: $secondary;
		background: #eaf7ff;
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
	}

	.quiz-results-info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 1px solid rgb(0, 0, 0, 0.25);
	}
}
