app-course-edit {

    .col-xl-5, .col-xl-7 {
        margin-bottom: 2rem;
    }

    .course-code {
        font-size: $font-size-xs;
        color: $sideBarText;
        border: 1px solid $warning;
        border-radius: 8px;
        background-color: white;
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background-color: $secondary;
    }
    .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background-color: shade-color($secondary, 15%);
    }

    .course-preview-content {
        color: $sideBarText;
        font-size: $font-size-sm;
    }

    #publishCourseLabel {
        color: $sideBarText;
    }
    
    .btn-back {
        color: $sideBarText;
        background-color: transparent;
        border-width: 0;
    }
    .btn-back:hover,
    .btn-back:first-child:active {
        color: tint-color($sideBarText, 40%);
    }

    #site_state,
    #courseCertificateSwitch {
        width: 3rem;
        height: 1.5rem;
        background-color: lightgray;
        border-color: lightgray;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28125, 125, 125, 1%29'/%3e%3c/svg%3e");
    }

    #site_state:checked,
    #courseCertificateSwitch:checked {
        background-color: $secondary;
        border-color: $secondary;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 1%29'/%3e%3c/svg%3e");
    }

    #exampleColorInput {
        height: 2.5rem;
        width: 5.5rem;
        border-width: 0;
        background-color: transparent;
    }
    #exampleColorInput::-webkit-color-swatch-wrapper {padding: 0;}
    #exampleColorInput::-webkit-color-swatch {
        border-color: lightgray;
        border-radius: 5px;
    }

    .upload-image-box {
        height: 165px;
        border-style: dashed;
        border-width: 2px;
        border-radius: 5px;
        border-color: tint-color($sideBarText, 40%);
    }

    .p-fileupload .p-fileupload-buttonbar,
    .p-fileupload .p-fileupload-content {
        border-style: dashed;
        border-width: 2px;
        border-radius: 5px;
        border-color: tint-color($sideBarText, 40%);
    }
    .p-fileupload {
        .p-fileupload-buttonbar {
            border-bottom-width: 0;
            background-color: transparent;
        }
        .p-fileupload-content {
            border-top-width: 0;
        }
    }

    .card-body {
        color: $sideBarText;
        label {
            font-size: $font-size-xs;
            font-weight: 600;
        }
    }
}