app-blogs-list {
	.dropdown-toggle:hover {
		cursor: pointer;
	}

	.dropdown-toggle::after {
		display: none;
	}

	.show {
		background: #e9ecef;
		box-shadow: 0 0 50px rgba(0, 0, 0, 0.03);
		border-radius: 16px;
	}

	.three-dot-icon {
		width: 40px;
		text-align: center;

		:hover {
			background: #e9ecef;
			box-shadow: 0 0 50px rgba(0, 0, 0, 0.03);
			border-radius: 16px;
		}
	}

	.p-dialog-header {
		justify-content: center;
	}

	.p-confirm-dialog .p-dialog-header {
		justify-content: space-between;
	}

	.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
		margin-left: 0;
	}

	.p-dialog.p-confirm-dialog .p-dialog-footer {
		display: flex;
		justify-content: space-between;
	}

	.p-dialog.p-confirm-dialog .p-dialog-footer button {
		width: 48%;
	}

	.p-dialog.p-confirm-dialog .p-dialog-footer {
		.p-button {
			padding: 0.5rem 1.25rem;
		}

		.p-confirm-dialog-accept {
			margin-right: 0;
			background-color: $secondary;
			border-color: $secondary;
		}

		.p-confirm-dialog-accept:hover {
			background-color: #0b6191;
			border-color: #0b6191;
		}

		.p-confirm-dialog-reject {
			background-color: white;
			border-color: $secondary;
			color: $secondary;
		}

		.p-confirm-dialog-reject:hover {
			background-color: #0b6191;
			border-color: #0b6191;
			color: white;
		}

		button .p-button-label {
			font-weight: 400;
		}
	}

	@media screen and (max-width: 960px) {
		.p-datatable-tbody > tr > td {
			gap: 3rem;
		}
	}

	@media screen and (max-width: 450px) {
		.p-input-icon-right,
		.p-input-icon-right > .p-inputtext,
		.create-btn,
		.create-btn .p-button {
			width: 100%;
		}
	}
}
