app-student-edit-courses {

    .checkbox-label {
        display: flex;
        align-items: center;
        padding: 0.5rem 0rem;
    }

    .table-button-text {
        color: $secondary;
        text-decoration: underline;
        background-color: transparent; 
        border: none;
    }

    .p-datatable .p-datatable-tbody > tr > td {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .p-dialog .p-dialog-content:last-of-type {
        border-top-right-radius: 1rem;
        border-top-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }

    @media screen and (max-width: 960px) {
        .card-body-content {
            padding: 0.5rem !important;
        }

        .p-datatable-tbody > tr > td {
            padding: 1rem 0rem;
        }

        .p-datatable-tbody > tr:last-child {
            border-bottom: none !important;
        }

        .p-datatable-tbody > tr > td:not(:last-child) {
            padding: 1rem 0rem;
        }

        .p-datatable-tbody > tr > td:last-child {
            padding: 1rem 0rem 2rem !important;
            padding-bottom: 1rem !important;
        }

        .p-datatable .p-datatable-tbody > tr:first-child > td {
            padding-top: 1rem;
        }

        .p-datatable-tbody > tr > td > .p-column-title {
            display: grid !important;
            align-items: center;
            border: 2px solid;
            border-radius: 1rem;
            border-color: tint-color($sideBarText, 70%);
            padding: 1rem;
            font-size: 18px;
            color: black;
            background-color: #fafbfc;
            text-align: center;
            max-width: 125px;
            min-height: 63px;
            padding: 0;
        }

        .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
            background-color: transparent;
        }

        .p-datatable .p-datatable-tbody > tr {
            border-bottom: 1px solid;
        }

        .p-datatable .p-datatable-tbody > tr > td .text-data {
            width: 50%;
        }

        .dropdown-toggle {
            height: 1.5rem;
            width: 3.5rem;
            vertical-align: middle;
            text-align: center;
            border: 1px solid;
            border-radius: 1rem;
        }

        .p-paginator .p-paginator-current {
            width: 100% !important;
        }
    }

	.p-dropdown {
        height: 38px;
		width: 100%;
        .p-inputtext {
            padding: 0.375rem 0.75rem;
            font-family: 'SofiaPro';
            color: $primary;
            justify-content: center;
        }
    }

    .p-dropdown:not(.p-disabled).p-focus {
        box-shadow: 0 0 0 0.2rem rgb(0 36 51 / 25%);
        border-color: #809299;
    }

    .p-dropdown:not(.p-disabled):hover {
        border-color: #ced4da !important;
    }
}
