app-navbar {

    nav {
        height: 5rem;
    }

    .nav-item {
        display: inline-block;
    }

    .p-avatar {
        background-color: $secondary;
        span.p-avatar-text {
            color: white;
            font-size: $font-size-base * 0.8125;
            font-weight: 700;
        }
    }

    a.dropdown-toggle {
        color: white;
        font-size: $font-size-base * 0.8125;
        .username {
            font-size: $font-size-base;
        }
    }

    .remove-dropdown-icon::after,
    .remove-dropdown-icon::before {
        content: none !important;
    }

    .navbar-nav .nav-link.active {
        color: $primary;
        background-color: white;
        padding-top: 1.8rem;
        padding-bottom: 1.8rem;
        padding: 1.8rem 1rem;
    }
}