app-blog-post-edit {
	.p-dropdown {
		width: 100%;
	}

	.ck-editor__editable {
		min-height: 20rem;
	}

	.progress-spinner-container {
		margin-top: 10rem;
	}

	.back-arrow-icon {
		cursor: pointer;
	}

	.p-dialog-header {
		justify-content: center;
	}
}
