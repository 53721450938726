app-pending-invitations {
    @media screen and (max-width: 960px) {

		.card-body-content {
			padding: 0.5rem !important;
		}

		.p-datatable .p-datatable-tbody > tr > td {
			padding-top: 2rem;
			padding-bottom: 2rem;
		}

		.p-datatable-tbody > tr > td {
			padding: 1rem 0rem;
		}

		.p-datatable-tbody > tr:last-child {
			border-bottom: none !important;
		}

		.p-datatable-tbody > tr > td:not(:last-child) {
			padding: 1rem 0rem;
		}

		.p-datatable-tbody > tr > td:last-child {
			padding: 1rem 0rem 2rem !important;
			padding-bottom: 1rem !important;
		}

		.p-datatable .p-datatable-tbody > tr:first-child > td {
			padding-top: 1rem;
		}

		.p-datatable-tbody > tr > td > .p-column-title {
			display: grid !important;
			align-items: center;
			border: 2px solid;
			border-radius: 1rem;
			border-color: tint-color($sideBarText, 70%);
			padding: 1rem;
			font-size: 18px;
			color: black;
			background-color: #fafbfc;
			text-align: center;
			max-width: 125px;
			min-height: 63px;
			padding: 0;
		}

		.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
			background-color: transparent;
		}

		.p-datatable .p-datatable-tbody > tr {
			border-bottom: 1px solid;
		}

		.p-datatable .p-datatable-tbody > tr > td .text-data {
			width: 50%;
		}

        .resend-btn, .delete-btn {
            width: 100%;
            padding-top: 1rem !important;
            padding-bottom: 1rem !important;
        }

        .p-input-icon-right {
            width: 100%;
        }
	}
}