app-course-registration {
    .p-steps .p-steps-item .p-menuitem-link {
        background: #fafbfc;
    }
    .p-steps .p-steps-item.p-highlight .p-steps-number {
        background: $primary;
        color: white !important;
        min-width: 2.5rem !important;
        height: 2.5rem !important;
        box-shadow: 0 0 0 0.5rem rgb(0 36 51 / 10%);
        padding: 0.5rem 0;
        display: flex;
    }

    .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
        color: $primary;
        background: $primary;
        min-width: 1.2rem;
        height: 1.2rem;
    }

    .p-steps-title {
        display: none;
    }

    .p-steps ul {
        padding: 0 2rem;
    }

    .p-steps ul > li:first-child {
        justify-content: start;

        a {
            margin-left: -2rem;
        }
    }

    .p-steps ul > li:last-child {
        justify-content: end;

        a {
            margin-right: -2rem;
        }
    }

    .p-steps .p-steps-item .p-menuitem-link {
        min-width: 4rem;
        height: 4rem;
        justify-content: center;
        margin-top: -2rem;
    }

    .p-steps .p-disabled {
        opacity: 1;
    }

    .registration-main {
        padding: 0 8rem;
    }

    .check-icon {
        color: $secondary;
        width: 2.5rem;
        height: 2.5rem;
        background: #CBEAFB;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 0px 10px #ebf8ff;
    }

    @include media-breakpoint-down(lg) {
        .registration-main {
            padding: 0 2rem;
        }
    }
}