app-login-info {
    p-avatar > div.p-avatar-circle.p-avatar-xl {
        width: 90px;
        height: 90px;
        
        .p-avatar-text {
            font-size: 26px;
        }
    }

    .student-avatar {
        color: #FAAE44;
        .p-avatar-circle {
            background-color: #FAE8CE;
        }
    }

    .link {
        color: $secondary;
        text-decoration: underline;
    }

    .p-dialog-header {
        flex-wrap: wrap;
    }

    @include media-breakpoint-down(lg) {
        .edit-info-btn, .cancel-btn, .save-changes-btn {
            width: 100%;
        }
    }
}