app-course-sessions-block-edit {

    .btn-back {
        color: $sideBarText;
        background-color: transparent;
        border-width: 0;
    }
    
    .btn-back:hover,
    .btn-back:first-child:active {
        color: tint-color($sideBarText, 40%);
    }

    .card-body {
        color: $sideBarText;
    }

    .card {
        border-radius: 1rem;
    }

    .form-control {
        font-weight: 700;
    }
    
    label {
        font-size: $font-size-xs;
    }
    input[type=text] {
        font-weight: 400;
    }
    input[type=text]::placeholder {
        font-weight: 400;
    }
}