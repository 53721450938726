app-ce-course-note {
	height: 100%;
	display: flex;
	flex-direction: column;

	.note-data {
		.ql-align-right {
			text-align: end;
		}

		.ql-align-center {
			text-align: center;
		}

		.ql-align-left {
			text-align: start;
		}

		p {
			margin-bottom: 0;

			img {
				max-width: 100%;
				max-height: 100%;
				height: auto !important;
				width: auto !important;
			}
		}
	}
}
