app-groups {
    .no-groups-create-header, .groups-create-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
        margin-left: 0;
    }

    .p-dialog .p-dialog-footer {
        display: flex;
        justify-content: space-between;
    }

    .p-dialog .p-dialog-footer button {
        width: 48%;
    }

    .p-dialog .p-dialog-footer {

        .p-button {
            padding: 0.5rem 1.25rem;
        }
        
        .p-confirm-dialog-accept {
            margin-right: 0;
            background-color: $secondary;
            border-color:  $secondary;
        }

        .p-confirm-dialog-accept:hover {
            background-color: #0b6191;
            border-color:  #0b6191;
        }

        .p-confirm-dialog-reject {
            background-color: white;
            border-color: $secondary;
            color: $secondary;
        }

        .p-confirm-dialog-reject:hover {
            background-color: #0b6191;
            border-color:  #0b6191;
            color: white;
        }

        button .p-button-label {
            font-weight: 400;
        }
    }

    .p-button-text {
        color: $secondary !important;
        padding-left: 0;
        padding-right: 0;

        .p-button-icon {
            font-size: 1.5rem;
        }

        .p-button-label {
            text-decoration: underline;
        }
    }

    .base-table .p-datatable .p-datatable-tbody > tr > td a {
        color: $sideBarText;
    }

    @include media-breakpoint-down(lg) {
        .no-groups-create-header {
            display: block;

            button {
                margin-bottom: 1rem;
            }
        }

        .unarchive-button {
            width: 100%;
        }

        .groups-create-header {
            display: block;
        }

        .my-groups-btns .btn-secondary {
            width: 100%;
        }

        .p-input-icon-right, .my-groups-btns {
            width: 100%;
        }

        .card-body-content {
            padding: 0.5rem !important;
        }

        .p-datatable-tbody > tr > td {
            padding: 1rem 0rem;
        }

        .p-datatable .p-datatable-tbody > tr > td a {
            font-size: 2rem !important;
        }

        .p-datatable-tbody > tr:last-child {
            border-bottom: none !important;
        }

        .p-datatable-tbody > tr > td:not(:last-child) {
            padding: 1rem 0rem;
        }

        .p-datatable-tbody > tr > td:last-child {
            padding: 1rem 0rem 2rem !important;
            padding-bottom: 1rem !important;
        }

        .p-datatable .p-datatable-tbody > tr:first-child > td {
            padding-top: 1rem;
        }

        .p-datatable-tbody > tr > td > .p-column-title {
            display: grid !important;
            align-items: center;
            border: 2px solid;
            border-radius: 1rem;
            border-color: tint-color($sideBarText, 70%);
            padding: 1rem;
            font-size: 18px;
            color: black;
            background-color: #fafbfc;
            text-align: center;
            max-width: 125px;
            min-height: 63px;
            padding: 0;
        }

        .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
            background-color: transparent;
        }

        .p-datatable .p-datatable-tbody > tr {
            border-bottom: 1px solid;
        }

        .p-datatable .p-datatable-tbody > tr > td .text-data {
            width: 50%;
        }

        .p-dialog .p-dialog-footer {
            display: block;

            button {
                width: 100%;
                margin: 0;
                margin-bottom: 1rem;
            }
        }
    }
}