app-program-roles {
    .p-inputnumber-input {
        width: 2.5rem !important;
        height: 1.5rem;
        margin: 0 0.18rem;
    }

    .p-inputtext {
        padding: 0 !important;
        text-align: center;
    }

    .role-block {
        margin-bottom: 3rem;
    }

    .card-body-content {
        padding: 1rem 2.5rem !important;
        padding-top: 2rem !important;
    }
    
    .color-primary {
        color: $primary;
    }

    .pi-ellipsis-v {
        width: 6px;
    }
    
    .p-datatable-reorderablerow-handle,
    [pReorderableColumn] {
        width: 6px;
    }
    
    .p-button.p-button-text {
        color: $secondary;
    }
    
    .btn-back {
        color: $sideBarText;
        background-color: transparent;
        border-width: 0;
    }
    
    .btn-back:hover,
    .btn-back:first-child:active {
        color: tint-color($sideBarText, 40%);
    }

    .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
        margin-left: 0;
    }

    .p-dialog .p-dialog-footer {
        display: flex;
        justify-content: space-between;
    }

    .p-dialog .p-dialog-footer button {
        width: 48%;
    }

    .p-dialog .p-dialog-footer {

        .p-button {
            padding: 0.5rem 1.25rem;
        }
        
        .p-confirm-dialog-accept {
            margin-right: 0;
            background-color: $secondary;
            border-color:  $secondary;
        }

        .p-confirm-dialog-accept:hover {
            background-color: #0b6191;
            border-color:  #0b6191;
        }

        .p-confirm-dialog-reject {
            background-color: white;
            border-color: $secondary;
            color: $secondary;
        }

        .p-confirm-dialog-reject:hover {
            background-color: #0b6191;
            border-color:  #0b6191;
            color: white;
        }

        button .p-button-label {
            font-weight: 400;
        }
    }

    .p-dropdown {
        height: 38px;

        .p-inputtext {
            padding: 0.375rem 0.75rem !important;
            font-family: 'SofiaPro';
            justify-content: center;
            text-align: left !important;
        }
    }

    .p-dropdown:not(.p-disabled).p-focus {
        box-shadow: 0 0 0 0.2rem rgb(0 36 51 / 25%);
        border-color: #809299;
    }

    .p-dropdown:not(.p-disabled):hover {
        border-color: #ced4da !important;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
        color: $secondary;
        background-color: tint-color($secondary, 90%);
    }

    .add-course {
        .p-dialog-content:last-of-type {
            border-bottom-right-radius: 1rem;
            border-bottom-left-radius: 1rem;
        }

        .p-dialog-content {
            padding: 0 1.5rem 1rem 1.5rem
        }
    }

    @media screen and (max-width: 1199px) {
        .card-body-content {
            padding: 0.5rem !important;
        }
    }
}