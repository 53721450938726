app-program-role-courses {
    .set-width-6 {
        width: 6px;
    }

    .content-type-container {
        color: $sideBarText;
    }

    .p-datatable .p-datatable-tbody > tr {
        background-color: #fafbfc;
        border-radius: 0;
    }
    .p-datatable .p-datatable-tbody > tr:first-child {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }
    .p-datatable .p-datatable-tbody > tr:last-child {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }

    .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
        background-color: #f4f7fb;
    }
}