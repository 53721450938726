app-ce-course-pdf {
	height: 100%;
	display: flex;
	flex-direction: column;

	.pdf-list {
		overflow-y: auto;
		flex: 1 1 auto;
		border-right: 5px solid rgba(0, 0, 0, 0.068);
	}

	.pdf-list > ul {
		padding-left: 0;
		margin-bottom: 0;
	}

	.pdf-list .active {
		background: $secondary;

		div {
			color: white !important;
		}
	}

	.pdf-list > ul > li {
		list-style-type: none;
		padding-bottom: 1rem;
	}

	.pdf-list > ul > li:first-child {
		border-top-left-radius: 1rem;
	}

	.pdf-list > ul > li:last-child {
		border-bottom-left-radius: 1rem;
	}

	.pdf-list > ul > li {
		border-bottom: 1px solid rgb(0, 0, 0, 0.25);
	}

	.pdf-list > ul > li:hover {
		background: $secondary;
		cursor: pointer;

		div {
			color: white !important;
		}
	}

	.display-item {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		border-top-right-radius: 1rem;
		border-bottom-right-radius: 1rem;
		background: #e9ecef;
	}

	.icon {
		padding-right: 3px;

		span {
			margin-top: 1rem;
			height: 20px;
			width: 20px;
			background: tint(#2d98d4, 80%);
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			color: $secondary;
			box-shadow: 0px 0px 0px 3px tint(#2d98d4, 90%);
		}
	}
}
