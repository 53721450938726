app-my-profile {
    p-avatar > div.p-avatar-circle.p-avatar-xl {
        width: 90px;
        height: 90px;
        
        .p-avatar-text {
            font-size: 26px;
        }
    }

    .student-avatar {
        color: #FAAE44;
        .p-avatar-circle {
            background-color: #FAE8CE;
        }
    }

    .link {
        color: $secondary;
    }

    .p-dialog .p-dialog-footer {
        justify-content: end;
    }

    @include media-breakpoint-down(lg) {
        .edit-info-btn, .cancel-btn, .save-changes-btn, .update-partner-logo-btn {
            width: 100%;
        }

        .p-dialog .p-dialog-footer {
            display: block;
        }

        .dialog-btns {
            button {
                width: 100% !important;
            }
        }
    }
}