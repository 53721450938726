app-edit-group {
    .addStudentDialog .p-dialog {
        width: 900px;
    }

    .p-dialog-header {
        flex-wrap: wrap;
    }

    .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
        margin-left: 0;
    }

    .p-dialog .p-dialog-footer {
        display: flex;
        justify-content: flex-end;
    }

    .p-dialog .p-dialog-footer button {
        width: 48%;
    }

    .p-dialog .p-dialog-footer {

        .p-button {
            padding: 0.5rem 1.25rem;
        }
        
        .p-confirm-dialog-accept {
            margin-right: 0;
            background-color: $secondary;
            border-color:  $secondary;
        }

        .p-confirm-dialog-accept:hover {
            background-color: #0b6191;
            border-color:  #0b6191;
        }

        .p-confirm-dialog-reject {
            background-color: white;
            border-color: $secondary;
            color: $secondary;
        }

        .p-confirm-dialog-reject:hover {
            background-color: #0b6191;
            border-color:  #0b6191;
            color: white;
        }

        button .p-button-label {
            font-weight: 400;
        }
    }

    ul > {
        li .partner-avatar {
            .p-avatar-text {
                font-size: $font-size-base * 0.8125;
            }
        }
        li:nth-child(3n + 1) .partner-avatar {
            color: #EB6598;
            .p-avatar-circle {
                background-color: tint-color(#EB6598, 90%);
            }
        }
        li:nth-child(3n + 2) .partner-avatar {
            color: #FAAE44;
            .p-avatar-circle {
                background-color: tint-color(#FAAE44, 90%);
            }
        }
        li:nth-child(3n + 3) .partner-avatar {
            color: #40B34E;
            .p-avatar-circle {
                background-color: tint-color(#40B34E, 90%);
            }
        }
    }

    .add-student {
        flex: 1;
        min-height: 0;
    }

    .dialog-card {
    
        ::-webkit-scrollbar {
            width: 14px;
        }
        
        ::-webkit-scrollbar-thumb {
            background-clip: padding-box;
            border-radius: 9999px;
            background-color: #AAAAAA;
        }

        ::-webkit-scrollbar-track {
            margin-block-end: .5rem;
        }
    }

    .student-col {
        display: flex;
        flex-direction: column;
        flex: 0 1 auto;
        min-height: 0px;
        border-right: 2px solid rgba(0, 0, 0, 0.068);

        .student-list {
            overflow-y: auto;
        }

        ::-webkit-scrollbar {
            width: 1.2vw;
        }
    
        ::-webkit-scrollbar-thumb {
            background-color: #E9ECEF;
            border-radius: 100vw;
        }
    
        ::-webkit-scrollbar-thumb:hover {
            background-color: darkgray;
        }
    
        ::-webkit-scrollbar-track {
            border: 1px solid rgba(0, 0, 0, .1);
            border-radius: 100vw;
            margin-block-end: .5rem;
        }
    }

    .student-selected-col {
        overflow-y: auto;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }

    .student-selected-col > ul > li {
        border-bottom: 1px solid rgb(0,0,0,0.25);
    }

    
    @include media-breakpoint-down(lg) {

        .add-students-btn {
            width: 100%;
        }

    }

    @include media-breakpoint-down(xl) {
        .student-col {
            height: 18rem !important;

            ::-webkit-scrollbar {
                width: 3vw;
            }
        }

        .student-selected-col {
            height: 14rem !important;
        }

        .dialog-card {
            display: block;
        }

        .student-col {
            border-right: 0;
            border-bottom: 2px solid rgba(0, 0, 0, 0.068);
        }

        .student-selected-col {
            border-top-right-radius: 0;
            border-bottom-right-radius: 1rem;
            border-bottom-left-radius: 1rem;
        }

        .dialog-btns {
            button {
                width: 100% !important;
            }
        }

        @include media-breakpoint-down(lg) {
            .no-groups-create-header {
                display: block;
    
                button {
                    margin-bottom: 1rem;
                }
            }
    
            .groups-create-header {
                display: block;
            }
    
            .my-groups-btns .btn-secondary {
                width: 100%;
            }
    
            .p-input-icon-right, .my-groups-btns {
                width: 100%;
            }
    
            .card-body-content {
                padding: 0.5rem !important;
            }
    
            .p-datatable-tbody > tr > td {
                padding: 1rem 0rem;
            }
    
            .p-datatable .p-datatable-tbody > tr > td a {
                font-size: 2rem !important;
            }
    
            .p-datatable-tbody > tr:last-child {
                border-bottom: none !important;
            }
    
            .p-datatable-tbody > tr > td:not(:last-child) {
                padding: 1rem 0rem;
            }
    
            .p-datatable-tbody > tr > td:last-child {
                padding: 1rem 0rem 2rem !important;
                padding-bottom: 1rem !important;
            }
    
            .p-datatable .p-datatable-tbody > tr:first-child > td {
                padding-top: 1rem;
            }
    
            .p-datatable-tbody > tr > td > .p-column-title {
                display: grid !important;
                align-items: center;
                border: 2px solid;
                border-radius: 1rem;
                border-color: tint-color($sideBarText, 70%);
                padding: 1rem;
                font-size: 18px;
                color: black;
                background-color: #fafbfc;
                text-align: center;
                max-width: 125px;
                min-height: 63px;
                padding: 0;
            }
    
            .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
                background-color: transparent;
            }
    
            .p-datatable .p-datatable-tbody > tr {
                border-bottom: 1px solid;
            }
    
            .p-datatable .p-datatable-tbody > tr > td .text-data {
                width: 50%;
            }
    
            .p-dialog .p-dialog-footer {
                display: block;
    
                button {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}