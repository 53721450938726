app-codes {
	.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
		margin-left: 0;
	}

	.p-dialog .p-dialog-footer {
		display: flex;
		justify-content: space-between;
	}

	.p-dialog .p-dialog-footer button {
		width: 48%;
	}

	.p-dialog .p-dialog-footer {
		.p-button {
			padding: 0.5rem 1.25rem;
		}

		.p-confirm-dialog-accept {
			margin-right: 0;
			background-color: $secondary;
			border-color: $secondary;
		}

		.p-confirm-dialog-accept:hover {
			background-color: #0b6191;
			border-color: #0b6191;
		}

		.p-confirm-dialog-reject {
			background-color: white;
			border-color: $secondary;
			color: $secondary;
		}

		.p-confirm-dialog-reject:hover {
			background-color: #0b6191;
			border-color: #0b6191;
			color: white;
		}

		button .p-button-label {
			font-weight: 400;
		}
	}

	.p-button-text {
		color: $secondary !important;
		padding-left: 0;
		padding-right: 0;

		.p-button-icon {
			font-size: 1.5rem;
		}

		.p-button-label {
			text-decoration: underline;
		}
	}

	@include media-breakpoint-down(lg) {
		.request-code-btn {
			width: 100%;
			margin-left: 0.5rem;
		}

		.offer-more-courses .text-data {
			width: 100%;
		}

		.p-dialog .p-dialog-footer {
			display: block;

			button {
				width: 100%;
				margin: 0;
				margin-bottom: 1rem;
			}
		}
	}
}
