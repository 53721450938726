app-home {
    p-avatar > div.p-avatar-circle.p-avatar-xl {
        width: 90px;
        height: 90px;
        
        .p-avatar-text {
            font-size: 26px;
        }
    }

    .student-avatar {
        color: #FAAE44;
        .p-avatar-circle {
            background-color: #FAE8CE;
        }
    }

    .welcome-wrapper {
        padding: 92px 54px 75px 54px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 16px;
        position: relative;
        z-index: 1;
      }

      .welcome-wrapper::after {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        content: "";
        z-index: -1;
        background-color: rgba(0, 0, 0, 0.55);
        border-radius: 16px;
      }

      .welcome-wrapp {
        max-width: 373px;
      }

      @media (max-width: 767.98px) {
        .welcome-wrapper {
          padding: 94px 25px 74px 22px;
        }
      }
}