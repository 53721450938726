app-session-block-notes {

    .ql-toolbar.ql-snow {
        font-family: 'SofiaPro';
    }

    .ql-container {
        font-family: 'SofiaPro';
    }
    
    .p-editor-container .p-editor-toolbar {
        background: white;
    }

    .notes-content-title {
        color: $primary;
    }

    .card-body-content {
        padding: 1.5rem;
    }

    @media screen and (max-width: 1199px) {
        .card-body-title {
            padding: 1rem 0 0 0.5rem;
            margin: 0 0.5rem !important;
        }

        .card-body-content {
            padding: 0.5rem;
        }

        .note-card {
            margin: 0 0.5rem 1rem !important;
        }
    }
}