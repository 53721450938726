app-program-edit {

    .col-xl-5, .col-xl-7 {
        margin-bottom: 2rem;
    }

    .program-code {
        font-size: $font-size-xs;
        color: $sideBarText;
        border: 1px solid $warning;
        border-radius: 8px;
        background-color: white;
    }

    span.p-ripple.p-element.p-button.p-component.p-fileupload-choose.p-button-icon-only {
        height: 37px;
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background-color: $secondary;
    }
    .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background-color: shade-color($secondary, 15%);
    }

    .program-preview-content {
        color: $sideBarText;
        font-size: $font-size-sm;
    }

    input[type=number] {
        width: 100px;
    }

    .num-courses-label, .num-electives-label {
        width: 5rem;
    }

    #publishProgramLabel {
        color: $sideBarText;
    }
    
    .btn-back {
        color: $sideBarText;
        background-color: transparent;
        border-width: 0;
    }
    .btn-back:hover,
    .btn-back:first-child:active {
        color: tint-color($sideBarText, 40%);
    }

    #site_state,
    #programCertificateSwitch {
        width: 3rem;
        height: 1.5rem;
        background-color: lightgray;
        border-color: lightgray;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28125, 125, 125, 1%29'/%3e%3c/svg%3e");
    }

    #site_state:checked,
    #programCertificateSwitch:checked {
        background-color: $secondary;
        border-color: $secondary;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 1%29'/%3e%3c/svg%3e");
    }

    .upload-image-box {
        height: 165px;
        border-style: dashed;
        border-width: 2px;
        border-radius: 5px;
        border-color: tint-color($sideBarText, 40%);
    }

    .p-fileupload .p-fileupload-buttonbar,
    .p-fileupload .p-fileupload-content {
        border-style: dashed;
        border-width: 2px;
        border-radius: 5px;
        border-color: tint-color($sideBarText, 40%);
    }
    .p-fileupload {
        .p-fileupload-buttonbar {
            border-bottom-width: 0;
            background-color: transparent;
        }
        .p-fileupload-content {
            border-top-width: 0;
        }
    }

    .card-body {
        color: $sideBarText;
        label {
            font-size: $font-size-xs;
            font-weight: 600;
        }
    }

    @media screen and (max-width: 1199px) {
        .num-courses-label, .num-electives-label {
            width: 100% !important;
        }

        .input-num {
            justify-content: space-between !important;
        }

        input[type=number] {
            width: 100%;
        }
    }
}