app-my-courses {
    .p-tabview .p-tabview-nav {
        background: transparent;
        font-size: 1rem;
        flex-wrap: wrap;
        border: solid #dee2e6;
        border-width: 0 0 2px 0;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        background: transparent;
        margin-right: 0;
        justify-content: center;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        background: transparent;
    }

    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
        background: transparent;
        color: $primary;
        border-color: $primary;
    }

    .p-tabview .p-tabview-nav .p-tabview-ink-bar {
        background: none;
    }

    .p-tabview .p-tabview-panels {
        background: transparent;
        padding-left: 0;
        padding-right: 0;
    }

    .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
        background-color: transparent;
    }

    .p-tabview .p-tabview-nav {

        li.p-highlight .p-tabview-nav-link .p-tabview-title,
        li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link .p-tabview-title {
            font-weight: 700;
        }
        
        .p-tabview-title {
            font-size: 15px;
            font-weight: 400;
        }
    }

    .p-progressbar {
        height: 0.4rem;

        .p-progressbar-label {
            display: none !important;
        }
    }

    .p-progressbar-determinate .p-progressbar-value {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    @media screen and (max-width: 1199px) {
        .btn-browse-programs, .btn-browse-courses {
            width: 100%;
            margin-right: 0;
            margin-top: 1rem;
        }

        .p-tabview .p-tabview-nav {
            justify-content: space-between;
            margin: 0;
        }

        .p-tabview .p-tabview-nav li {
            flex-grow: 1;
        }

        .p-tabview .p-tabview-nav li .p-tabview-nav-link {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
}