app-course-pre-checkout {
    .pre-checkout-main {
        padding: 0 8rem;
    }

    .img-course-icon > img {
        width: 40px;
        height: auto;
    }

    @include media-breakpoint-down(lg) {
        .pre-checkout-main {
            padding: 0 2rem;
        }
    }
}