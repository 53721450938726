app-profile {

    p-avatar > div.p-avatar-circle.p-avatar-xl {
        width: 90px;
        height: 90px;
        
        .p-avatar-text {
            font-size: 26px;
        }
    }

    .student-avatar {
        color: #FAAE44;
        .p-avatar-circle {
            background-color: #FAE8CE;
        }
    }

    .student-id {
        border: 1px solid #FAAE44;
        border-radius: 8px;
        background-color: white;
    }

    .p-calendar {
        margin-bottom: 0.5rem;
        height: 50px;
        
        @include media-breakpoint-up(xl) {
            width: 10rem;
            margin-bottom: 0;
        }

        .p-inputtext {
            border-right: none;
        }

        .p-button {
            background: #F8F9FA;
            color: $sideBarText;
            border: 1px solid #ced4da;
            border-left: none;

            .pi {
                font-size: 1.5rem;
            }
        }

        .p-button:hover {
            background: #F8F9FA;
            color: $sideBarText;
            border-color: #ced4da;
        }

        .p-button:focus {
            box-shadow: none
        }

        .p-button.p-button-icon-only {
            padding-right: 1rem;
        }

        .p-inputtext:enabled:focus + .p-button {
            border-color: #3B82F6;
            border-right-color: none;
            border-left-color: none;
        }

        .p-inputtext:enabled:hover + .p-button {
            border-color: #3B82F6;
            
    }

        .p-inputtext:enabled:focus {
            box-shadow: none;
        }

        .isax {
            font-size: 2rem;
        }
    }

    .link {
        color: $secondary;
    }

    @include media-breakpoint-down(xl) {
        .student {
            width: 100%;
            margin-top: 2rem;
        }
    }
}