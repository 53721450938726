app-student {
    .tools > span,
    .tools > span input.p-inputtext {
        width: 100%;
    }
    @include media-breakpoint-up(xl) {
        .tools > span,
        .tools > span input.p-inputtext {
            width: auto;
        }
    }

    .p-calendar {
        width: 10rem;

		.p-inputtext {
			border-right: none;
		}

		.p-button {
			background: white;
			color: $secondary;
			border: 1px solid #ced4da;
			border-left: none;

			.pi {
				font-size: 1.5rem;
			}
		}

		.p-button:hover {
			background: $secondary;
		}

		.p-inputtext:enabled:focus + .p-button {
			border-color: #3b82f6;
			border-right-color: none;
			border-left-color: none;
		}

		.p-inputtext:enabled:hover + .p-button {
			border-color: #3b82f6;
		}

		.p-inputtext:enabled:focus {
			box-shadow: none;
		}
	}

    .dropdown-toggle:hover {
        cursor: pointer;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .p-datatable .p-datatable-tbody > {
        tr .student-avatar {
            .p-avatar-text {
                font-size: $font-size-base * 0.8125;
            }
        }
        tr:nth-child(3n + 1) .student-avatar {
            color: #EB6598;
            .p-avatar-circle {
                background-color: tint-color(#EB6598, 90%);
            }
        }
        tr:nth-child(3n + 2) .student-avatar {
            color: #FAAE44;
            .p-avatar-circle {
                background-color: tint-color(#FAAE44, 90%);
            }
        }
        tr:nth-child(3n + 3) .student-avatar {
            color: #40B34E;
            .p-avatar-circle {
                background-color: tint-color(#40B34E, 90%);
            }
        }
    }

    .dropdown-item {
        color: $sideBarText;
    }

    .dropdown-item:active,
    .dropdown-item:hover {
        color: $secondary;
        background-color: transparent;
    }

    .student-details-container {
        border-radius: 1rem;
    }

    li .active {
        background-color: #eb6598;
    }

    .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
        margin-left: 0;
    }

    .p-dialog .p-dialog-footer {
        display: flex;
        justify-content: space-between;
    }

    .p-dialog .p-dialog-footer button {
        width: 48%;
    }

    .p-dialog .p-dialog-footer {

        .p-button {
            padding: 0.5rem 1.25rem;
        }
        
        .p-confirm-dialog-accept {
            margin-right: 0;
            background-color: $secondary;
            border-color:  $secondary;
        }

        .p-confirm-dialog-accept:hover {
            background-color: #0b6191;
            border-color:  #0b6191;
        }

        .p-confirm-dialog-reject {
            background-color: white;
            border-color: $secondary;
            color: $secondary;
        }

        .p-confirm-dialog-reject:hover {
            background-color: #0b6191;
            border-color:  #0b6191;
            color: white;
        }

        button .p-button-label {
            font-weight: 400;
        }
    }

    @media screen and (max-width: 960px) {
        .card-body-content {
            padding: 0.5rem !important;
        }

        .p-datatable-tbody > tr > td {
            padding: 1rem 0rem;
        }

        .p-datatable-tbody > tr:last-child {
            border-bottom: none !important;
        }

        .p-datatable-tbody > tr > td:not(:last-child) {
            padding: 1rem 0rem;
        }

        .p-datatable-tbody > tr > td:last-child {
            padding: 1rem 0rem 2rem !important;
            padding-bottom: 1rem !important;
        }

        .p-datatable .p-datatable-tbody > tr:first-child > td {
            padding-top: 1rem;
        }

        .p-datatable-tbody > tr > td > .p-column-title {
            display: grid !important;
            align-items: center;
            border: 2px solid;
            border-radius: 1rem;
            border-color: tint-color($sideBarText, 70%);
            padding: 1rem;
            font-size: 18px;
            color: black;
            background-color: #fafbfc;
            text-align: center;
            max-width: 125px;
            min-height: 63px;
            padding: 0;
        }

        .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
            background-color: transparent;
        }

        .p-datatable .p-datatable-tbody > tr {
            border-bottom: 1px solid;
        }

        .p-datatable .p-datatable-tbody > tr > td .text-data {
            width: 50%;
        }

        .dropdown-toggle {
            height: 1.5rem;
            width: 3.5rem;
            vertical-align: middle;
            text-align: center;
            border: 1px solid;
            border-radius: 1rem;
        }

        .p-paginator .p-paginator-current {
            width: 100% !important;
        }
    }
}