app-ce-course-assessment-details {
	height: 100%;
	display: flex;
	flex-direction: column;

	.assessment-details-container {
		position: static;
		width: auto;

		@include media-breakpoint-up(xl) {
			position: fixed;
			width: 23.333%;
		}
	}

	.assessment-results-container {
		position: static;
		width: auto;

		@include media-breakpoint-up(xl) {
			position: fixed;
			width: 23.333%;
		}
	}

	.time-remain-title {
		text-align: center;
		color: $secondary;
		background: #fafbfc;
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
		border-bottom: 1px solid rgb(0, 0, 0, 0.1);
	}

	.p-dialog {
		width: 393px;
	}

	.icon {
		span {
			height: 55px;
			width: 55px;
			background: tint($secondary, 85%);
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			color: $secondary;
			box-shadow: 0px 0px 0px 10px tint($secondary, 95%);
		}
	}

	.p-dialog .p-dialog-header {
		display: none;
	}

	.p-dialog .p-dialog-content {
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
	}
}
