app-view-program {
    .view-program-main {
        padding: 0 8rem;
    }

    .p-steps .p-steps-item .p-menuitem-link {
        background: #fafbfc;
    }
    .p-steps .p-steps-item.p-highlight .p-steps-number {
        background: $primary;
        color: white !important;
        min-width: 2.5rem !important;
        height: 2.5rem !important;
        box-shadow: 0 0 0 0.5rem rgb(0 36 51 / 10%);
        padding: 0.5rem 0;
        display: flex;
    }

    .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
        color: $primary;
        background: $primary;
        min-width: 1.2rem;
        height: 1.2rem;
    }

    .p-steps-title {
        display: none;

    }

    .p-steps ul {
        padding: 0 2rem;
    }

    .p-steps ul > li:first-child {
        justify-content: left;
        
        a {
            margin-left: -2rem;
        }
    }

    .p-steps ul > li:last-child {
        justify-content: right;

        a {
            margin-right: -2rem;
        }
    }

    .p-steps .p-steps-item .p-menuitem-link {
        min-width: 4rem;
        height: 4rem;
        justify-content: center;
        margin-top: -2rem;
    }

    .p-steps .p-disabled {
        opacity: 1;
    }

    .p-progressbar {
        height: 0.4rem;

        .p-progressbar-label {
            display: none !important;
        }
    }

    .p-progressbar-determinate .p-progressbar-value {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        background: $primary;
    }

    .completed {

        i, div{
            color: #47B99E !important;
        }
    }

    .course-selected {
        display: none;
    }

    .select-course {
        display: block;
    }

    .p-checkbox-label {
        color: $primary;
    }

    .p-checkbox .p-checkbox-box.p-highlight {
        color: $secondary;
        background-color: $secondary;
    }

    .p-datatable .p-datatable-tbody > tr:has(.p-checkbox .p-checkbox-box.p-highlight) > td {
        .text-data, label {
            color: $secondary;
        }

        img {
            filter: invert(24%) sepia(98%) saturate(1296%) hue-rotate(177deg) brightness(70%) contrast(90%);
        }

        .course-selected {
            display: block;
        }

        .select-course {
            display: none;
        }
    }

    @include media-breakpoint-down(lg) {
        .view-program-main {
            padding: 0 1rem;
        }
    }
}