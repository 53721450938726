app-default-view-program {
	.p-datatable .p-datatable-thead > tr > th {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}

	.p-datatable-tbody > tr > td:last-child {
		min-width: max-content;
	}
}
