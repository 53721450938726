app-session-block-exam {

    .answer-input {
        width: 100%;
        margin-right: 1rem;
        margin-left: 1rem;
    }

    .exam-content-title h5 {
        color: $primary;
    }

    .exam-table-icons > i {
        color: $sideBarText;
    }

    .pi-ellipsis-v {
        width: 6px;
    }

    input[type=number] {
        width: 125px;
    }

    .card-body-content {
        padding: 1.5rem;
    }

    .btn-exam .p-button.p-button-text {
        color: $secondary;
    }

    .btn-add-question, .btn-discard-changes {
        width: 10rem;
    }

    .btn-discard-changes {
        margin-right: 1.5rem;
    }

    .custom-checkbox {
        background-color: white;
        border-radius: 0.5rem;
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .custom-checkbox input:focus {
        border: none;
    }

    .form-check-input:checked {
        background-color: $secondary;
        border-color: none;
    }

    .custom-checkbox:has(.form-check-input:checked) {
        background: #EAF7FF;
        border: 1px solid rgba(13, 114, 170, 0.25);
    }

    @media screen and (max-width: 1199px) {
        .card-body-title {
            padding: 1rem 0 0 0.5rem;
            margin: 0 0.5rem !important;
        }

        .card-body-content {
            padding: 0.5rem;
        }

        .exam-card {
            margin: 0 0.5rem 1rem !important;
        }

        .btn-add-question {
            width: 100%;
            margin-bottom: 1rem;
        }

        .btn-discard-changes {
            width: 100%;
            margin-right: 0;
        }
    }
}