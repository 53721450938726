app-ce-course {
	.ce-sidebar {
		display: flex;
		flex-direction: column;
		width: 320px;
		height: 100%;
		transition: all 0.2s ease-in-out;
		background: #08192b;
	}
	@media (max-width: 1199px) {
		.ce-sidebar {
			display: none;
		}
	}
	.ce-sidebar-header {
		border-bottom: 1px solid rgba(233, 236, 239, 0.2);
	}
	.sidebar-course-content {
		background: #08192b;
		overflow-y: auto;
	}

	.pulse span {
		height: 22px;
		width: 22px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #e4f2f9;
		box-shadow: 0px 0px 0px 5px rgba(13, 114, 170, 0.2);
	}

	.link {
		color: white;
	}

	.p-button.p-button-secondary {
		background: rgba(255, 255, 255, 0.2);
		border: none;
	}

	.p-button.p-button-icon-only.p-button-rounded {
		height: 2rem;
		width: 2rem;
		font-size: 0.5rem;
	}

	.p-breadcrumb {
		background: white;
		border: none;
		border-radius: 0;
		font-size: 12px;
	}

	.p-breadcrumb ul li:last-child .p-menuitem-text {
		color: #2d98d4;
	}

	.main-course {
		display: flex;
		flex-direction: column;
		padding: 0 5rem;
		height: 100%;
		overflow-y: auto;
	}

	.p-sidebar {
		background: #08192b;
		color: white;

		.p-sidebar-content {
			display: flex;
			flex-direction: column;
			height: 100%;
			padding: 0;

			.pulse i {
				color: $secondary;
			}
		}
	}

	.p-sidebar-course-content {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	.p-sidebar .p-sidebar-footer {
		display: none;
	}

	.p-progressbar {
		height: 0.4rem;

		.p-progressbar-label {
			display: none !important;
		}
	}

	.p-sidebar .p-sidebar-header .p-sidebar-close,
	.p-sidebar .p-sidebar-header .p-sidebar-icon {
		background: rgba(255, 255, 255, 0.2);
		border: none;
		border-radius: 50%;
		color: white;

		.pi {
			font-size: 0.8rem;
		}
	}

	.p-accordion:hover {
		background: transparent;
		color: white;
	}

	.p-accordion {
		margin-bottom: 0;

		.p-accordion-tab {
			border-top: 1px solid rgba(255, 255, 255, 0.2);
			margin-bottom: 0;
		}

		.p-accordion-tab:last-child {
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		}

		.active .p-accordion-header:not(.p-highlight) {
			background: rgba(255, 255, 255, 0.5);
		}
	}

	.p-accordion .p-accordion-header .p-accordion-header-link {
		padding: 1rem 0rem;
		border: none;
		color: white;
		background: transparent;
		font-weight: normal;
		font-size: 13px;
		border-radius: 0;
		display: block;

		.p-accordion-toggle-icon {
			position: absolute;
			margin-top: 6px;
			right: 0;
			margin-right: 0;
			font-size: 10px;
		}
	}

	.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
		background: transparent;
		border-color: none;
		color: white;
	}

	.p-accordion
		.p-accordion-header:not(.p-highlight):not(.p-disabled):hover
		.p-accordion-header-link {
		background: transparent;
		border-color: none;
		color: white;
	}

	.p-accordion .p-accordion-header:not(.p-disabled):hover .p-accordion-header-link {
		background: transparent;
		border-color: none;
		color: white;
	}

	.p-accordion .p-accordion-header {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
		background: transparent;
		border-color: none;
		color: white;
	}

	.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
		box-shadow: none;
	}

	.p-accordion .p-accordion-content {
		padding: 0;
		border: none;
		color: rgba(255, 255, 255, 0.6);
		background: transparent;
		font-weight: normal;
		font-size: 13px;
		border-radius: 0;
	}

	.p-accordion .p-accordion-content {
		.icon-other {
			padding-right: 3px;

			span {
				height: 18px;
				width: 18px;
				background: rgba(255, 255, 255, 0.2);
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				color: white;
				box-shadow: 0px 0px 0px 3px rgb(255 255 255 / 15%);
			}
		}

		.icon {
			padding-right: 3px;

			span {
				height: 18px;
				width: 18px;
				background: rgba(255, 255, 255, 0.2);
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				color: rgb(56, 203, 137);
				box-shadow: 0px 0px 0px 3px rgb(255 255 255 / 15%);
			}
		}
	}

	.section-link {
		text-decoration: none;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: rgba(255, 255, 255, 0.05);
		color: white;

		&.active {
			background-color: rgba(255, 255, 255, 0.5);
		}
	}

	.section-link:hover {
		color: white;
		cursor: pointer;
	}

	.courseCompletedModalIcon {
		span {
			height: 55px;
			width: 55px;
			background: tint($secondary, 85%);
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			color: $secondary;
			box-shadow: 0px 0px 0px 10px tint($secondary, 95%);
		}
	}

	@include media-breakpoint-down(xl) {
		.main-course {
			padding: 0 1rem;
		}

		.btn-completed {
			width: 100%;
			margin-top: 1rem;

			button {
				width: 100%;
			}
		}
	}
}
