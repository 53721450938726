app-partners-code-permissions {
	.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
		margin-left: 0;
	}

	.p-dialog .p-dialog-footer {
		display: flex;
		justify-content: space-between;
	}

	.p-dialog .p-dialog-footer button {
		width: 48%;
	}

	.p-dialog .p-dialog-footer {
		.p-button {
			padding: 0.5rem 1.25rem;
		}

		.p-confirm-dialog-accept {
			margin-right: 0;
			background-color: $secondary;
			border-color: $secondary;
		}

		.p-confirm-dialog-accept:hover {
			background-color: #0b6191;
			border-color: #0b6191;
		}

		.p-confirm-dialog-reject {
			background-color: white;
			border-color: $secondary;
			color: $secondary;
		}

		.p-confirm-dialog-reject:hover {
			background-color: #0b6191;
			border-color: #0b6191;
			color: white;
		}

		button .p-button-label {
			font-weight: 400;
		}
	}

	@media screen and (max-width: 960px) {
		.card-body-content {
			padding: 0.5rem !important;
		}

		.text-data {
			padding-left: 0.5rem !important;
		}

		.p-datatable-tbody > tr > td {
			padding: 1rem 0rem;
		}

		.p-datatable-tbody > tr:last-child {
			border-bottom: none !important;
		}

		.p-datatable-tbody > tr > td:not(:last-child) {
			padding: 1rem 0rem;
		}

		.p-datatable-tbody > tr > td:last-child {
			padding: 1rem 0rem 2rem !important;
			padding-bottom: 1rem !important;
		}

		.p-datatable .p-datatable-tbody > tr:first-child > td {
			padding-top: 1rem;
		}

		.p-datatable-tbody > tr > td > .p-column-title {
			border: 2px solid;
			border-radius: 1rem;
			border-color: tint-color($sideBarText, 70%);
			padding: 1rem;
			font-size: 18px;
			color: black;
			background-color: #fafbfc;
			text-align: center;
			min-width: 146px;
		}

		.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
			background-color: transparent;
		}

		.p-datatable .p-datatable-tbody > tr {
			border-bottom: 1px solid;
		}

		.p-datatable .p-datatable-tbody > tr > td .text-data {
			width: 50%;
		}

		.btn-outline-primary,
		.btn-primary {
			width: 100%;
			margin-right: 0 !important;
		}

		.edit-code-td {
			flex-wrap: wrap;
		}

		.dropdown {
			margin-right: 0 !important;
			margin-top: 1.5rem;
		}

		.dropdown-toggle {
			height: 1.5rem;
			width: 3.5rem;
			vertical-align: middle;
			text-align: center;
			border: 1px solid;
			border-radius: 1rem;
		}

		.delete-code {
			margin-top: 1rem;
		}
	}
}
