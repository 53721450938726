app-session-block-flashcard {
    .flashcard-table-padding {
        padding: 0.5rem;
    }

    .front-card {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .back-card {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .pi-ellipsis-v {
        width: 6px;
    }

    .flashcard-table-icons > i {
        color: $sideBarText;
    }

    .btn-flashcard .p-button.p-button-text {
        color: $secondary;
    }

    .btn-add-flashcard, .btn-discard-changes {
        width: 10rem;
    }

    .btn-discard-changes {
        margin-right: 1.5rem;
    }

    .card-body-content {
        padding: 1.5rem;
    }

    @media screen and (max-width: 1199px) {
        .card-body-title {
            padding: 1rem 0 0 0.5rem;
            margin: 0 0.5rem !important;
        }

        .card-body-content {
            padding: 0.5rem;
        }

        .flashcard-card {
            margin: 0 0.5rem 1rem !important;
        }

        .btn-flashcard .p-button.p-button-text {
            color: $secondary;
        }

        .btn-add-flashcard {
            width: 100%;
            margin-bottom: 1rem;
        }

        .btn-discard-changes {
            width: 100%;
            margin-right: 0;
        }

        .front-card {
            border: none;
            border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
            padding-bottom: 3rem;
            padding-right: 0 !important;
        }

        .back-card {
            border: none;
            border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
            padding-top: 2rem;
            padding-left: 0 !important;
        }
    }
}