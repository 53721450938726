app-student-details {
	p-avatar > div.p-avatar-circle.p-avatar-xl {
		width: 90px;
		height: 90px;

		.p-avatar-text {
			font-size: 26px;
		}
	}

	.student-avatar {
		color: #faae44;
		.p-avatar-circle {
			background-color: #fae8ce;
		}
	}

	.student-id {
		border: 1px solid tint(#faae44, 70%);
		border-radius: 8px;
		background-color: white;
	}

	.p-datatable .p-datatable-tbody > tr > td {
		padding: 1.5rem 1rem;
	}

	.student-details-main {
		padding: 1rem 8rem;
	}

	@media screen and (max-width: 960px) {
		.card-body-content {
			padding: 0.5rem !important;
		}

		.p-datatable .p-datatable-tbody > tr > td {
			padding-top: 2rem;
			padding-bottom: 2rem;
		}

		.p-datatable-tbody > tr > td {
			padding: 1rem 0rem;
		}

		.p-datatable-tbody > tr:last-child {
			border-bottom: none !important;
		}

		.p-datatable-tbody > tr > td:not(:last-child) {
			padding: 1rem 0rem;
		}

		.p-datatable-tbody > tr > td:last-child {
			padding: 1rem 0rem 2rem !important;
			padding-bottom: 1rem !important;
		}

		.p-datatable .p-datatable-tbody > tr:first-child > td {
			padding-top: 1rem;
		}

		.p-datatable-tbody > tr > td > .p-column-title {
			display: grid !important;
			align-items: center;
			border: 2px solid;
			border-radius: 1rem;
			border-color: tint-color($sideBarText, 70%);
			padding: 1rem;
			font-size: 18px;
			color: black;
			background-color: #fafbfc;
			text-align: center;
			width: 125px;
			height: 63px;
			padding: 0;
		}

		.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
			background-color: transparent;
		}

		.p-datatable .p-datatable-tbody > tr {
			border-bottom: 1px solid;
		}

		.p-datatable .p-datatable-tbody > tr > td .text-data {
			width: 50%;
		}
	}
}
