app-student-edit-program-certificates {
    @media screen and (max-width: 960px) {
        .card-body-content {
            padding: 0.5rem !important;
        }
        
        .p-datatable-tbody > tr > td {
            display: revert !important;
        }

        .p-datatable-tbody > tr:not(:last-child) > td:not(:last-child) {
            border: 1px solid #dee2e6 !important;
            border-width: 0 0 1px 0 !important;
        }
    }
}