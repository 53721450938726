app-payment-history {
    .base-table .p-datatable th:last-child {
        border-bottom-right-radius: 0;
        text-align: center;
    }

    .base-table .p-datatable th:first-child {
        border-bottom-left-radius: 0;
        padding-left: 2rem;
    }

    .base-table .p-datatable .p-datatable-tbody > tr > td:first-child {
        padding-left: 2rem;
    }

    .base-table .p-datatable .p-datatable-tbody > tr > td:last-child {
        padding-right: 1rem;
    }

    .base-table .p-datatable .p-datatable-thead > tr {
        border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
    }

    @media screen and (max-width: 960px) {
        .base-table .p-datatable .p-datatable-tbody > tr > td:first-child {
            padding-left: 1rem;
        }

        .base-table .p-datatable .p-datatable-thead > tr {
            border-bottom: none;
        }

        .base-table .p-datatable .p-datatable-tbody > tr > td {
            padding-left: 1rem;
        }

        .p-datatable .p-datatable-tbody > tr {
            background: none;
        }

        .p-datatable-tbody > tr > td > .p-column-title {
            display: grid !important;
            align-items: center;
            border: 2px solid;
            border-radius: 1rem;
            border-color: tint-color($sideBarText, 70%);
            padding: 1rem;
            font-size: 18px;
            color: black;
            background-color: #fafbfc;
            text-align: center;
            max-width: 125px;
            min-height: 63px;
            padding: 0;
        }

        .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
            background-color: transparent;
        }

        .p-datatable .p-datatable-tbody > tr > td .text-data {
            width: 50%;
        }
    }
    
}