app-partners-account-info {
    .p-dropdown {
        height: 38px;

        .p-inputtext {
            padding: 0.375rem 0.75rem;
            font-family: 'SofiaPro';
            color: $primary;
            justify-content: center;
        }
    }

    .p-dropdown:not(.p-disabled).p-focus {
        box-shadow: 0 0 0 0.2rem rgb(0 36 51 / 25%);
        border-color: #809299;
    }

    .p-dropdown:not(.p-disabled):hover {
        border-color: #ced4da !important;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
        color: $secondary;
        background-color: tint-color($secondary, 90%);
    }
}