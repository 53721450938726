app-course {
	.mt-xl-5 {
		margin-top: 1.5rem;
		@include media-breakpoint-up(xl) {
			margin-top: 3rem;
		}
	}

	.card-body {
		padding: 2rem 3rem;
	}

	.p-datatable {
		font-size: $font-size-sm;
	}

	#newCourseButton {
		font-size: $font-size-sm;
	}

	.p-datatable .p-datatable-tbody > tr:first-child > td {
		border-top: none;
	}

	.p-datatable .p-datatable-tbody > tr > td {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	th:first-child {
		border-top-left-radius: 1rem;
		border-bottom-left-radius: 1rem;
	}

	th:last-child {
		border-top-right-radius: 1rem;
		border-bottom-right-radius: 1rem;
	}

	#coursesTableContainer {
		border-radius: 1rem;
	}

	.dropdown-toggle:hover {
		cursor: pointer;
	}

	.dropdown-toggle::after {
		display: none;
	}

	.p-paginator-current {
		display: none;
		@include media-breakpoint-up(xl) {
			display: flex;
		}
	}

	.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
		margin-left: 0;
	}

	.p-dialog .p-dialog-footer {
		display: flex;
		justify-content: space-between;
	}

	.p-dialog .p-dialog-footer button {
		width: 48%;
	}

	.p-dialog .p-dialog-footer {
		.p-button {
			padding: 0.5rem 1.25rem;
		}

		.p-confirm-dialog-accept {
			margin-right: 0;
			background-color: $secondary;
			border-color: $secondary;
		}

		.p-confirm-dialog-accept:hover {
			background-color: #0b6191;
			border-color: #0b6191;
		}

		.p-confirm-dialog-reject {
			background-color: white;
			border-color: $secondary;
			color: $secondary;
		}

		.p-confirm-dialog-reject:hover {
			background-color: #0b6191;
			border-color: #0b6191;
			color: white;
		}

		button .p-button-label {
			font-weight: 400;
		}
	}

	@media screen and (max-width: 1199px) {
		.tools > span,
		.tools > span input.p-inputtext,
		.p-dropdown,
		.create-btn {
			width: 100%;
		}
	}

	@media screen and (max-width: 960px) {
		.p-datatable-tbody > tr > td {
			padding: 1rem 0rem;
		}

		.p-datatable-tbody > tr > td:not(:last-child) {
			padding: 1rem 0rem;
		}

		.p-datatable-tbody > tr > td:last-child {
			padding: 1rem 0rem 2rem !important;
		}

		.p-datatable .p-datatable-tbody > tr:first-child > td {
			padding-top: 1rem;
		}

		.p-datatable-tbody > tr > td > .p-column-title {
			border: 2px solid;
			border-radius: 1rem;
			border-color: tint-color($sideBarText, 70%);
			padding: 1rem;
			font-size: 18px;
			color: black;
			background-color: #fafbfc;
			text-align: center;
		}

		#coursesTableContainer .p-datatable .p-datatable-tbody > tr > td {
			border-top: none;
			text-align: left;
			justify-content: flex-start;
			> span {
				margin-right: 1rem;
				width: 50%;
			}
		}
		.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
			background-color: transparent;
		}

		.p-datatable .p-datatable-tbody > tr {
			border-bottom: 1px solid;
		}

		.p-datatable .p-datatable-tbody > tr > td .text-data {
			width: 50%;
		}

		.card-body {
			padding: 2rem 1rem;
		}

		.dropdown-toggle {
			height: 1.5rem;
			width: 3.5rem;
			vertical-align: middle;
			text-align: center;
			border: 1px solid;
			border-radius: 1rem;
		}
	}
}
