app-course-sessions-edit {

    .browse-course-content {
        color: $sideBarText;
        font-size: $font-size-sm;
    }

    .form-check-label {
        font-size: $font-size-xs;
        color: $sideBarText;
    }

    .form-label {
        font-size: $font-size-xs;
        color: $sideBarText;
    }

    .btn-create-session, .btn-cancel-session {
        width: 10rem;
    }

    .btn-cancel-session {
        margin-right: 1.5rem;
    }

    .form-check-input:checked {
        background-color: $secondary;
        border-color: $secondary;
    }

    @media screen and (max-width: 1199px) {
        .btn-create-session {
            width: 100%;
            margin-bottom: 1rem;
        }

        .btn-cancel-session {
            width: 100%;
            margin-right: 0;
        }
    }
}

