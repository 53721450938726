app-partner {
    .tools > span,
    .tools > span input.p-inputtext {
        width: 100%;
    }
    @include media-breakpoint-up(xl) {
        .tools > span,
        .tools > span input.p-inputtext {
            width: auto;
        }
    }

    .p-calendar {
        width: 10rem;

		.p-inputtext {
			border-right: none;
		}

		.p-button {
			background: white;
			color: $secondary;
			border: 1px solid #ced4da;
			border-left: none;

			.pi {
				font-size: 1.5rem;
			}
		}

		.p-button:hover {
			background: $secondary;
		}

		.p-inputtext:enabled:focus + .p-button {
			border-color: #3b82f6;
			border-right-color: none;
			border-left-color: none;
		}

		.p-inputtext:enabled:hover + .p-button {
			border-color: #3b82f6;
		}

		.p-inputtext:enabled:focus {
			box-shadow: none;
		}
	}

    thead {
        text-align: left;
        vertical-align: middle;
        height: 60px;
    }

    .dropdown-toggle:hover {
        cursor: pointer;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .p-datatable {
        font-size: $font-size-sm;
    }

    .p-datatable .p-datatable-tbody > {
        tr .partner-avatar {
            .p-avatar-text {
                font-size: $font-size-base * 0.8125;
            }
        }
        tr:nth-child(3n + 1) .partner-avatar {
            color: #EB6598;
            .p-avatar-circle {
                background-color: tint-color(#EB6598, 90%);
            }
        }
        tr:nth-child(3n + 2) .partner-avatar {
            color: #FAAE44;
            .p-avatar-circle {
                background-color: tint-color(#FAAE44, 90%);
            }
        }
        tr:nth-child(3n + 3) .partner-avatar {
            color: #40B34E;
            .p-avatar-circle {
                background-color: tint-color(#40B34E, 90%);
            }
        }
    }
    
    .p-datatable .p-datatable-thead > tr > th {
        color: $sideBarText;
        font-weight: 600;
    }

    .p-datatable .p-datatable-tbody > tr > td {
        color: $sideBarText;
    }

    th:first-child {
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }

    th:last-child {
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }

    .dropdown-item {
        color: $sideBarText;
    }

    .dropdown-item:active,
    .dropdown-item:hover {
        color: $secondary;
        background-color: transparent;
    }

    .ellipsis-dropdown {
        padding: 0;
        border-radius: 35%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .ellipsis-dropdown,
    .ellipsis-dropdown.show,
    .ellipsis-dropdown:first-child:active {
        color: $sideBarText;
        background-color: transparent;
        border: 0;
    }

    .ellipsis-dropdown:hover {
        color: $sideBarText;
        background-color: lightgray;
        border: 0;
    }

    .ellipsis-dropdown::after,
    .ellipsis-dropdown::before {
        content: none !important;
    }

    .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
        margin-left: 0;
    }

    .p-dialog .p-dialog-footer {
        display: flex;
        justify-content: space-between;
    }

    .p-dialog .p-dialog-footer button {
        width: 48%;
    }

    .p-dialog .p-dialog-footer {

        .p-button {
            padding: 0.5rem 1.25rem;
        }
        
        .p-confirm-dialog-accept {
            margin-right: 0;
            background-color: $secondary;
            border-color:  $secondary;
        }

        .p-confirm-dialog-accept:hover {
            background-color: #0b6191;
            border-color:  #0b6191;
        }

        .p-confirm-dialog-reject {
            background-color: white;
            border-color: $secondary;
            color: $secondary;
        }

        .p-confirm-dialog-reject:hover {
            background-color: #0b6191;
            border-color:  #0b6191;
            color: white;
        }

        button .p-button-label {
            font-weight: 400;
        }
    }

    @include media-breakpoint-up(xl) {
        .search-x {
            width: 224px;
            position: relative;
        }
    }

    .search-x .input {
        padding: 5px 15px;
        height: 42px;
        width: 100%;
        border-radius: 16px;
        background: #FFFFFF;
        border: 1px solid #E9ECEF;
    }

    .search-x .lens {
        top: 10px;
        right: 13px;
        width: 21px;
        position: absolute;
    }

    li .active {
        background-color: #eb6598;
    }

    @media screen and (max-width: 960px) {

        thead {
            display: none;
        }

        .card-body-content {
            padding: 0.5rem !important;
        }

        .name {
            margin-left: 1rem;
        }

        .text-data {
            margin-left: 0.5rem !important;
        }

        .p-datatable-tbody > tr > td {
            padding: 1rem 0rem;
        }

        .p-datatable-tbody > tr > td:not(:last-child) {
            padding: 1rem 0rem;
        }

        .p-datatable-tbody > tr > td:last-child {
            padding: 1rem 0rem 2rem !important;
        }

        .p-datatable .p-datatable-tbody > tr:first-child > td {
            padding-top: 1rem;
        }

        .p-datatable-tbody > tr > td > .p-column-title {
            border: 2px solid;
            border-radius: 1rem;
            border-color: tint-color($sideBarText, 70%);
            font-size: 18px;
            padding: auto;
            color: black;
            background-color: #fafbfc;
            text-align: center;
            width: 9rem !important;
            height: 4rem;
        }

        #partnersTableContainer .p-datatable .p-datatable-tbody > tr > td {
            border-top: none;
            text-align: left;
            justify-content: flex-start;
            > span {
                margin-right: 1rem;
                width: 50%;
            }
        }
        .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
            background-color: transparent;
        }

        .p-datatable .p-datatable-tbody > tr {
            border-bottom: 1px solid;
        }

        .p-datatable .p-datatable-tbody > tr > td .text-data {
            width: 50%;
        }

        .card-body {
            padding: 2rem 1rem;
        }

        .dropdown-toggle {
            height: 1.5rem;
            width: 3.5rem;
            vertical-align: middle;
            text-align: center;
            border: 1px solid;
            border-radius: 1rem;
        }
    }
}