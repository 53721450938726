app-approval {
	.tools > span,
	.tools > span input.p-inputtext {
		width: 100%;
	}

	.p-tabmenu .p-tabmenu-nav .p-menuitem-badge {
		background: $secondary;
	}

	@include media-breakpoint-up(xl) {
		.tools > span,
		.tools > span input.p-inputtext {
			width: auto;
		}
	}

	.p-tabview-title {
		font-size: $font-size-xs;
	}

	.p-tabview .p-tabview-nav {
		background: transparent;
		font-size: 1rem;
		flex-wrap: wrap;
		border: solid #dee2e6;
		border-width: 0 0 2px 0;
	}

	.p-tabview .p-tabview-nav li .p-tabview-nav-link {
		background: transparent;
		margin-right: 0;
		justify-content: center;
	}

	.p-tabview .p-tabview-nav li .p-tabview-nav-link {
		background: transparent;
	}

	.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
		background: transparent;
		color: $primary;
		border-color: $primary;
	}

	.p-tabview .p-tabview-nav .p-tabview-ink-bar {
		background: none;
	}

	.p-tabview .p-tabview-panels {
		background: transparent;
		padding-left: 0;
		padding-right: 0;
	}

	@media screen and (max-width: 1199px) {
		.p-tabview .p-tabview-nav {
			justify-content: space-between;
			margin: 0;
		}

		.p-tabview .p-tabview-nav li {
			flex-grow: 1;
		}
	}
}
