app-enrollment {
    .link {
        color: $secondary;
    }

    .p-dialog .p-dialog-footer {
        justify-content: end;
    }

    .p-datatable .p-datatable-tbody > tr > td {
        padding: 1.5rem 1rem;
    }

    @include media-breakpoint-down(xl) {
        .p-dialog .p-dialog-footer {
            display: block;
        }

        .dialog-btns {
            button {
                width: 100% !important;
            }
        }
    }
}