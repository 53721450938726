app-session-block-pdf {

    .pdf-table-padding {
        padding: 0.5rem;
        border: solid lightgray 1px;
        border-radius: 5px;
        margin-bottom: 1rem;
    }
    
    .pdf-table-padding:last-child {
        margin-bottom: 0;
    }
    
    tr {
        margin-bottom: 1rem;
    }
    
    tr:last-child {
        margin-bottom: 0;
    }
    
    .p-datatable .p-datatable-tbody > tr.p-highlight {
        background: #e9ecef;
    }
    
    .p-datatable .p-datatable-tbody > tr:focus {
        outline: none;
    }

    .pi-ellipsis-v {
        width: 6px;
    }

    .pdf-table-icons > i {
        color: $sideBarText;
    }

    .btn-pdf .p-button.p-button-text {
        color: $secondary;
    }

    .btn-add-pdf, .btn-discard-changes {
        width: 10rem;
    }

    .btn-discard-changes {
        margin-right: 1.5rem;
    }

    .card-body-content {
        padding: 1.5rem;
    }

    @media screen and (max-width: 1199px) {
        .card-body-title {
            padding: 1rem 0 0 0.5rem;
            margin: 0 0.5rem !important;
        }

        .card-body-content {
            padding: 0.5rem;
        }

        .pdf-card {
            margin: 0 0.5rem 1rem !important;
        }

        .btn-add-pdf {
            width: 100%;
            margin-bottom: 1rem;
        }

        .btn-discard-changes {
            width: 100%;
            margin-right: 0;
        }
    }
}