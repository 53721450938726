app-ce-course-sidebar {
	.link:hover {
		cursor: pointer;
	}

	.p-progressbar {
		height: 0.4rem;

		.p-progressbar-label {
			display: none !important;
		}
	}

	.p-progressbar-determinate .p-progressbar-value {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	.p-accordion:hover {
		background: transparent;
		color: white;
	}

	.p-accordion {
		margin-bottom: 0;

		.p-accordion-tab {
			border-top: 1px solid rgba(255, 255, 255, 0.2);
			margin-bottom: 0;
		}

		.p-accordion-tab:last-child {
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		}

		.active .p-accordion-header:not(.p-highlight) {
			background: rgba(255, 255, 255, 0.5);
		}
	}

	.p-accordion .p-accordion-header {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.p-accordion .p-accordion-header .p-accordion-header-link {
		padding: 1rem 0;
		border: none;
		color: white;
		background: transparent;
		font-weight: normal;
		font-size: 13px;
		border-radius: 0;
		display: block;

		.p-accordion-toggle-icon {
			position: absolute;
			margin-top: 6px;
			right: 0;
			margin-right: 0;
			font-size: 10px;
		}
	}

	.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
		background: transparent;
		border-color: none;
		color: white;
	}

	.p-accordion
		.p-accordion-header:not(.p-highlight):not(.p-disabled):hover
		.p-accordion-header-link {
		background: transparent;
		border-color: none;
		color: white;
	}

	.p-accordion .p-accordion-header:not(.p-disabled):hover .p-accordion-header-link {
		background: transparent;
		border-color: none;
		color: white;
	}

	.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
		background: transparent;
		border-color: none;
		color: white;
	}

	.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
		box-shadow: none;
	}

	.p-accordion .p-accordion-content {
		padding: 0;
		border: none;
		color: rgba(255, 255, 255, 0.6);
		background: transparent;
		font-weight: normal;
		font-size: 13px;
		border-radius: 0;
	}

	.p-accordion .p-accordion-content {
		.icon-other {
			padding-right: 3px;

			span {
				height: 18px;
				width: 18px;
				background: rgba(255, 255, 255, 0.2);
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				color: white;
				box-shadow: 0px 0px 0px 3px rgb(255 255 255 / 15%);
			}
		}

		.icon {
			padding-right: 3px;

			span {
				height: 18px;
				width: 18px;
				background: rgba(255, 255, 255, 0.2);
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				color: rgb(56, 203, 137);
				box-shadow: 0px 0px 0px 3px rgb(255 255 255 / 15%);
			}
		}
	}

	.sessionModalLockIcon {
		span {
			height: 55px;
			width: 55px;
			background: tint($secondary, 85%);
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			color: $secondary;
			box-shadow: 0px 0px 0px 10px tint($secondary, 95%);
		}
	}

	.section-link {
		text-decoration: none;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: rgba(255, 255, 255, 0.05);
		color: white;

		&.active {
			background-color: rgba(255, 255, 255, 0.5);
		}
	}

	.section-link:hover {
		color: white;
		cursor: pointer;
	}
}
