app-session-block-video {

    .ql-toolbar.ql-snow {
        font-family: 'SofiaPro';
    }

    .ql-container {
        font-family: 'SofiaPro';
    }

    .p-editor-container .p-editor-toolbar {
        background: white;
    }
    .p-tabview-title {
        font-size: $font-size-xs;
    }

    .p-tabview .p-tabview-nav {
        background: #f8f9fa;
        font-size: 1rem;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        background: #f8f9fa;
        padding-left: 0;
        padding-right: 0;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        background: #f8f9fa;
        padding-left: 0;
        padding-right: 0;
    }

    .p-disabled {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
        background: #f8f9fa;
        color: $primary;
        border-color: $primary;
        padding-right: 0;
        padding-left: 0;
    }

    .p-tabview .p-tabview-nav .p-tabview-ink-bar {
        background: none;
    }

    .p-tabview .p-tabview-panels {
        background: #f8f9fa;
        padding-left: 0;
        padding-right: 0;
    }

    input[type=text]:placeholder-shown {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    @media screen and (max-width: 1199px) {
        .card-body-title {
            padding: 1rem 0 0 0.5rem;
            margin: 0 0.5rem !important;
        }

        .card-body-content {
            margin: 0.5rem 0.5rem 1rem !important;
        }

        .video-card {
            margin: 0 0.5rem 1rem !important;
        }
    }

    @media screen and (max-width: 600px) {
        
        .p-tabview .p-tabview-nav {
            justify-content: space-between;
        }
    }
}