app-approvals-partner-name-change {
    @media screen and (max-width: 960px) {

        .card-body-content {
            padding: 0.5rem !important;
        }

        .text-data {
            margin-left: 0.5rem !important;
        }

        .p-datatable-tbody > tr > td {
            padding: 1rem 0rem;
        }

        .p-datatable-tbody > tr > td:not(:last-child) {
            padding: 1rem 0rem;
        }

        .p-datatable-tbody > tr > td:last-child {
            padding: 1rem 0rem 2rem !important;
            display: block !important;
        }

        .p-datatable .p-datatable-tbody > tr:first-child > td {
            padding-top: 1rem;
        }

        .p-datatable-tbody > tr > td > .p-column-title {
            display: grid !important;
            align-items: center;
            border: 2px solid;
            border-radius: 1rem;
            border-color: tint-color($sideBarText, 70%);
            font-size: 18px;
            padding: auto;
            color: black;
            background-color: #fafbfc;
            text-align: center;
            width: 9rem !important;
            height: 4rem;
        }

        .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
            background-color: transparent;
        }

        .p-datatable .p-datatable-tbody > tr {
            border-bottom: 1px solid;
        }

        .p-datatable .p-datatable-tbody > tr > td .text-data {
            width: 50%;
        }

        .p-paginator .p-paginator-current {
            width: 100% !important;
        }

        .btn-approve, .btn-deny {
            margin: 0 !important;
            margin-top: 1rem !important;
            width: 100%;
        }
    }
}